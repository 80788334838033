<!--
 * @Author: Link
 * @Date: 2021-08-20 10:49:36
 * @LastEditTime: 2021-09-02 16:46:16
-->
<template>
  <div :class="`chart-panel `" @animationend="isAnimation = false">
    <div class="header">
      <div class="switch">
        <div class="switch--active" :style="`left:${switchTab == 'order' ? '0' : '50%'}`"></div>
        <div :class="`switch-item ${switchTab == 'order' && 'active'}`" @click="switchTab = 'order'">
          <span>订单</span>
        </div>
        <div :class="`switch-item ${switchTab == 'user' && 'active'}`" @click="switchTab = 'user'">
          <span>用户</span>
        </div>
      </div>
      <div v-if="switchTab == 'order'">
        <div class="legend">
          <div
            :class="`legend-item ${!item.show && 'hidden'}`"
            v-for="(item, index) in chart1.lines"
            :key="item.name"
            @click="legendClick(index)"
          >
            <i :style="`background:${item.color}`"></i> {{ item.name }}
          </div>
        </div>
      </div>
      <div v-else>
        <div class="legend">
          <div
            :class="`legend-item ${!item.show && 'hidden'}`"
            v-for="(item, index) in chart3.lines"
            :key="item.name"
            @click="legendClick(index)"
          >
            <i :style="`background:${item.color}`"></i> {{ item.name }}
          </div>
        </div>
      </div>
      <div class="filter">
        <div class="filter-item filter-area">
          <el-select v-model="filter.area" placeholder="请选择" :disabled="!$store.getters['user/isAllArea']">
            <el-option label="全国" :value="-1"></el-option>
            <el-option
              v-for="item in $store.getters['user/userAreaInfoListWithoutGlobal']"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <div class="filter-item filter-date">
          <i class="el-icon-date"></i> {{ filterDate[0] }} 至 {{ filterDate[1] }}
          <div class="date-picker">
            <el-date-picker
              v-model="filter.date"
              type="datetimerange"
              align="right"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div
        :style="`max-height:${switchTab == 'order' ? '1200px' : '0'};transition:all ${
          switchTab == 'order' ? '1s' : '.5s'
        };overflow:hidden`"
      >
        <line-chart :lines="chart1.lines" :data="chart1.data" :loading="loading" />
        <div class="divider"></div>
        <line-chart :lines="chart2.lines" :data="chart2.data" :twoYAxis="false" :legend="true" :loading="loading" />
      </div>
      <div
        :style="`max-height:${switchTab == 'user' ? '600px' : '0'};transition:all ${
          switchTab == 'user' ? '.5s' : '0.8s'
        };overflow:hidden`"
      >
        <line-chart :lines="chart3.lines" :data="chart3.data" :loading="loading" :twoYAxis="false" yAxisName="人" />
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '../_com/lineChart.vue'
export default {
  components: { LineChart },
  data() {
    return {
      isAnimation: false,
      pickerOptions: {
        shortcuts: [
          {
            text: '本月至今',
            onClick(picker) {
              const today = new Date()
              picker.$emit('pick', [
                new Date(today.getFullYear(), today.getMonth(), 1),
                new Date(new Date().setHours(23, 59, 59, 0))
              ])
            }
          },
          {
            text: '今年至今',
            onClick(picker) {
              const end = new Date()
              const start = new Date(new Date().getFullYear(), 0)
              picker.$emit('pick', [start, new Date(end.setHours(23, 59, 59, 0))])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setMonth(start.getMonth() - 3)
              picker.$emit('pick', [start, new Date(end.setHours(23, 59, 59, 0))])
            }
          },
          {
            text: '最近六个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setMonth(start.getMonth() - 6)
              picker.$emit('pick', [start, new Date(end.setHours(23, 59, 59, 0))])
            }
          }
        ]
      },
      switchTab: 'order',
      loading: false,
      filter: {
        area: -1,
        date: ''
      },
      chart1: {
        lines: [
          {
            name: '金额',
            color: '#F7BE55',
            shadowColor: 'rgba(248, 191, 86, 0.4)',
            yAxisIndex: 0,
            show: true
          },
          {
            name: '商家销售额',
            color: 'rgb(112, 101, 241)',
            shadowColor: 'rgba(113, 101, 241, 0.39)',
            yAxisIndex: 0,
            show: true
          },
          {
            name: '订单数',
            color: 'rgb(71, 246, 152)',
            shadowColor: 'rgba(71, 246, 152, 0.39)',
            yAxisIndex: 1,
            show: true
          }
        ],
        data: []
      },
      chart2: {
        lines: [
          {
            name: '平台销售额',
            color: 'rgb(248, 107, 153)',
            shadowColor: 'rgba(248, 107, 153, 0.39)',
            yAxisIndex: 0,
            show: true
          },
          {
            name: '退款金额',
            color: 'rgb(197, 58, 245)',
            shadowColor: 'rgba(197, 58, 245, 0.39)',
            yAxisIndex: 0,
            show: true
          },
          {
            name: '实付金额',
            color: 'rgb(57, 183, 255)',
            shadowColor: 'rgba(57, 184, 255, 0.39)',
            yAxisIndex: 0,
            show: true
          }
        ],
        data: []
      },
      chart3: {
        lines: [
          {
            name: '新增用户',
            color: '#F7BE55',
            shadowColor: 'rgba(248, 191, 86, 0.4)',
            yAxisIndex: 0,
            show: true
          },
          {
            name: '总用户',
            color: 'rgb(112, 101, 241)',
            shadowColor: 'rgba(113, 101, 241, 0.39)',
            yAxisIndex: 0,
            show: true
          },
          {
            name: '下单用户',
            color: 'rgb(71, 246, 152)',
            shadowColor: 'rgba(71, 246, 152, 0.39)',
            yAxisIndex: 0,
            show: true
          }
        ],
        data: []
      }
    }
  },
  watch: {
    switchTab(v) {
      this.isAnimation = true
      if (v == 'order') {
        this.getOrderData()
        this.$emit('change', false)
      } else {
        this.getUserData()
        this.$emit('change', true)
      }
    },
    'filter.date'() {
      if (this.switchTab == 'order') {
        this.getOrderData()
      } else {
        this.getUserData()
      }
    },
    'filter.area'() {
      if (this.switchTab == 'order') {
        this.getOrderData()
      } else {
        this.getUserData()
      }
    }
  },
  computed: {
    filterDate() {
      if (!this.filter.date) {
        return ['开始日期', '结束日期']
      }
      console.log(this.filter.date)
      let _start = this.filter.date[0]
      let _end = this.filter.date[1]
      let start = {
        year: _start.getFullYear(),
        month: _start.getMonth() + 1,
        day: _start.getDate()
      }
      let end = {
        year: _end.getFullYear(),
        month: _end.getMonth() + 1,
        day: _end.getDate()
      }
      let start_str =
        (start.month < 10 ? '0' + start.month : start.month) + '-' + (start.day < 10 ? '0' + start.day : start.day)
      let end_str = (end.month < 10 ? '0' + end.month : end.month) + '-' + (end.day < 10 ? '0' + end.day : end.day)
      let nowYear = new Date().getFullYear()
      if (nowYear !== start.year) start_str = start.year + '-' + start_str
      if (nowYear !== end.year) end_str = end.year + '-' + end_str
      return [start_str, end_str]
    }
  },
  created() {
    if (this.$store.getters['user/currentAreaId'] == -1) {
      this.filter.area = -1
    } else if (
      this.$store.getters['user/currentAreaId'] == -1 &&
      this.$store.getters['user/userAreaInfoListWithoutGlobal']
    ) {
      this.filter.area = this.$store.getters['user/userAreaInfoListWithoutGlobal'][0].id
    } else {
      this.filter.area = sessionStorage.currentAreaId
    }
    this.filter.area = parseInt(this.filter.area)
    let start = new Date(new Date().setDate(new Date().getDate() - 30))
    this.filter.date = [new Date(start.setHours(0, 0, 0, 0)), new Date(new Date().setHours(23, 59, 59, 0))]
  },
  methods: {
    legendClick(index) {
      if (this.switchTab == 'order') {
        let item = this.chart1.lines[index]
        item.show = !item.show
        this.$set(this.chart1.lines, index, item)
      } else {
        let item = this.chart3.lines[index]
        item.show = !item.show
        this.$set(this.chart3.lines, index, item)
      }
    },
    getOrderData() {
      if (!this.filter.date) return
      this.chart1.data = []
      this.chart2.data = []
      this.loading = true

      this.$http
        .get('/boom-center-statistics-service/sysAdmin/homestatistic/saleChart', {
          params: {
            queryType: 0,
            startTime: this.filter.date[0].getTime(),
            endTime: this.filter.date[1].getTime()
          },
          headers: {
            ['Bm-Area-Id']: this.filter.area
          }
        })
        .then(res => {
          let temp1 = []
          let temp2 = []
          res.map(item => {
            temp1.push([item.time, item.sellMoney, item.businessShareMoney, item.orderCount])
            temp2.push([item.time, item.platformShareMoney, item.refundMoney, item.actualPayMoney])
          })
          this.chart1.data = temp1
          this.chart2.data = temp2
        })
        .finally(() => {
          this.loading = false
        })
    },
    getUserData() {
      this.chart3.data = []
      this.loading = true

      this.$http
        .get('/boom-center-statistics-service/sysAdmin/homestatistic/memberChart', {
          params: {
            queryType: 1,
            startTime: this.filter.date[0].getTime(),
            endTime: this.filter.date[1].getTime()
          },
          headers: {
            ['Bm-Area-Id']: this.filter.area
          }
        })
        .then(res => {
          let temp = []
          res.map(item => {
            temp.push([item.time, item.newMemberCount, item.totalMemberCount, item.payMemberCount])
          })
          this.chart3.data = temp
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style lang='less' scoped>
.chart-panel {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 12px -4px rgba(179, 181, 186, 0.08);
  padding: 15px;
  &.animation {
    animation: tab-change 0.5s;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .switch {
      width: fit-content;
      height: fit-content;
      background: rgba(245, 247, 251, 1);
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      white-space: nowrap;
      // &:hover {
      //   background: rgba(0, 0, 0, 0.1);
      // }
      .switch-item {
        display: inline-block;
        padding: 8px 30px;
        font-size: 15px;
        color: rgba(128, 129, 145, 1);
        font-weight: bold;
        cursor: pointer;
        user-select: none;
        transition: all 0.3s ease-out;
        height: fit-content;
        font-family: HarmonyOS Sans SC;

        span {
          position: relative;
          z-index: 10;
        }
        &.active {
          color: #fff;
        }
      }
      .switch--active {
        position: absolute;
        width: 50%;
        height: 100%;
        z-index: 9;
        border-radius: 8px;
        top: 0;
        left: 0;
        transition: all 0.4s;
        background: rgb(231, 20, 26);
      }
    }
    .legend {
      display: flex;
      .legend-item {
        font-size: 14px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        width: fit-content;
        cursor: pointer;
        user-select: none;
        color: rgba(102, 102, 102, 1);
        white-space: nowrap;
        font-family: HarmonyOS Sans SC;
        &.hidden {
          color: #e0e0e0;
          i {
            background: #e0e0e0 !important;
          }
        }
        &:active {
          filter: brightness(90%);
        }
        i {
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 4px;
          margin-right: 5px;
        }
      }
    }
    .filter {
      white-space: nowrap;
      .filter-item {
        position: relative;
        background: rgba(246, 247, 251, 1);
        padding: 3px 0;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.3s;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        font-family: HarmonyOS Sans SC;
        &:active {
          filter: brightness(95%);
        }
        &.filter-area {
          margin-right: 30px;
          /deep/.el-input__inner {
            width: fit-content;
            border: none;
            background: none;
            width: 100px;
            font-size: 15px;
            color: rgba(128, 129, 145, 1);
            user-select: none;
          }
        }
        &.filter-date {
          padding: 9px 15px;
          width: fit-content;
          font-size: 15px;
          color: rgba(128, 129, 145, 1);
          .date-picker {
            position: absolute;
            z-index: 9;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
          }
          /deep/.el-input__inner {
            width: fit-content;
            border: none;
            background: none;
            user-select: none;
            .el-range-input {
              background: none;
            }
          }
        }
      }
    }
  }
  .content {
    width: 100%;
    .divider {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin: 45px 0;
      width: 90%;
      height: 1px;
      background: #efefef;
    }
  }
}
@keyframes tab-change {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}
</style>