<!--
 * @Author: Link
 * @Date: 2021-08-20 15:04:00
 * @LastEditTime: 2021-08-31 20:13:36
-->
<template>
  <div class="line-chart">
    <div ref="chart" class="chart"></div>
    <loading :loading="loading" :color="lines[0].color" />
  </div>
</template>

<script>
import echarts from 'echarts'
import loading from './loading.vue'
export default {
  components: { loading },
  props: {
    lines: {
      type: Array
    },
    data: {
      type: Array
    },
    twoYAxis: {
      //第二个Y轴
      type: Boolean,
      default: true
    },
    yAxisName: {
      type: String,
      default: '金额'
    },
    legend: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chart: ''
    }
  },
  watch: {
    lines(v) {
      this.renderChart()
    },
    data() {
      this.renderChart()
    }
  },
  mounted() {
    this.initChart()
    this.renderChart()
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.chart)
    },
    renderChart() {
      this.chart.clear()
      let options = {
        color: this.lines.map(item => item.color),
        legend: {
          show: this.legend,
          icon: 'roundRect',
          left: 370,
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 15,

          textStyle: {
            fontSize: 14,
            color: 'rgba(102, 102, 102, 1)',
            fontFamily: 'HarmonyOS Sans SC'
          }
        },
        grid: {
          left: 80,
          top: 80,
          right: 100,
          bottom: 30
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(3, 1, 23, 0.9)',
          padding: 15,
          displayMode: 'single',
          textStyle: {
            color: '#fff',
            fontSize: 15
          },
          axisPointer: {
            type: 'line',
            animation: true,
            animationDurationUpdate: 700,
            lineStyle: {
              color: 'rgba(215, 217, 221, 1)'
            }
          }
        },
        // dataZoom: [
        //   {
        //     show: this.data.length > 120,
        //     realtime: true,
        //     start: 0,
        //     end: 20
        //   }
        // ],
        series: this.lines.map(item => {
          return {
            type: 'line',
            symbol: 'emptyCircle',
            showSymbol: false,
            name: item.name,
            smooth: true,
            yAxisIndex: item.yAxisIndex,
            lineStyle: {
              color: item.color,
              width: item.show != false ? (this.data.length < 100 ? 2 : 1) : 0,
              shadowBlur: 6,
              shadowOffsetX: 0,
              shadowOffsetY: 4,
              shadowColor: item.shadowColor
            }
          }
        }),
        xAxis: {
          type: 'category',
          axisLabel: {
            margin: 15,
            fontFamily: 'HarmonyOS Sans SC',
            textStyle: {
              fontSize: 14,
              color: 'rgba(51, 51, 51, 1)'
            },
            formatter: function (value) {
              let arr = value.split('/')
              if (arr.length >= 3) arr = arr.slice(1)
              return arr.join('-')
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(215, 217, 221, 1)'
            }
          }
        },
        // 声明一个 Y 轴，数值轴。
        yAxis: [
          {
            name: this.yAxisName,
            nameTextStyle: {
              color: '#333',
              align: 'right',
              lineHeight: 30
            },
            axisLabel: {
              fontFamily: 'HarmonyOS Sans SC',
              margin: 15,
              textStyle: {
                fontSize: 14,
                color: 'rgba(51, 51, 51, 1)'
              }
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(215, 217, 221, 1)'
              }
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(222, 226, 235, 1)',
                type: 'dashed'
              }
            }
          },
          {
            name: '订单数',
            show: this.twoYAxis,
            nameTextStyle: {
              color: '#333',
              align: 'left',
              lineHeight: 30
            },
            axisLabel: {
              textStyle: {
                fontSize: 14,
                color: '#000'
              }
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(215, 217, 221, 1)'
              }
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(222, 226, 235, 1)',
                type: 'dashed'
              }
            }
          }
        ],
        dataset: {
          source: this.data
        }
      }
      this.chart.setOption(options)
    }
  }
}
</script>

<style lang='less' scoped>
.line-chart {
  width: 100%;
  // height: 409px;
  height: 460px;
  position: relative;
  .chart {
    width: 100%;
    height: 100%;
  }
}
</style>